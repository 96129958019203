import { useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { auth } from '../../firebase/app';

import BookList from './BookList';
import Loading from '../../components/Loading';
import { BookOrder } from '../../types/BookOrder';

const BooksPage = () => {
  const navigate = useNavigate();
  // User Authentication
  const [user, loading, error] = useAuthState(auth);

  const [order, setOrder] = useState(BookOrder.CreatedAt);

  const handleChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value as BookOrder);
  };

  const onNewBookClick = () => {
    navigate('/books/new');
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <>
      <div style={{
        margin: "0 60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}>
        <h2>書籍一覧</h2>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Button sx={{ height: 48 }} variant="contained" onClick={onNewBookClick}>新規追加</Button>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="book-order-label">並び順</InputLabel>
          <Select
            labelId="book-order-label"
            id="book-order"
            value={order}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={BookOrder.CreatedAt}>作成日時</MenuItem>
            <MenuItem value={BookOrder.Title}>タイトル</MenuItem>
            <MenuItem value={BookOrder.Author}>著者</MenuItem>
          </Select>
        </FormControl>
        </div>
      </div>
      {user && <BookList uid={user.uid} order={order} />}
    </>
  );
};

export default BooksPage;
