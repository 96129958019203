import React from 'react';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import BookPage from "./pages/book/BookPage";
import CollectionPage from "./pages/collection/CollectionPage";
import MemoPage from "./pages/memo/MemoPage";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import TermPage from "./pages/term/TermPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import BooksPage from "./pages/books/BooksPage";
import NewBookPage from "./pages/new-book/NewBookPage";
import MemosPage from "./pages/memos/MemosPage";
import NewMemoPage from "./pages/new-memo/NewMemoPage";
import AboutAppPage from './pages/about-app/AboutAppPage';
import FusenAppBar from './components/FusenAppBar';

const App = () => {
  return (
    <BrowserRouter>
      <FusenAppBar />
      <div style={{
        padding: "8px 32px",
      }}>
        <Routes>
          <Route path={`/`} element={<HomePage />} />
          <Route path={`/books`} element={<BooksPage />} />
          <Route path={`/books/new`} element={<NewBookPage />} />
          <Route path={`/books/:id`} element={<BookPage />} />
          <Route path={`/collections/:id`} element={<CollectionPage />} />
          <Route path={`/memos`} element={<MemosPage />} />
          <Route path={`/memos/:id`} element={<MemoPage />} />
          <Route path={`/memos/new/:bookId`} element={<NewMemoPage />} />
          <Route path={`/about-app`} element={<AboutAppPage />} />
          <Route path={`/privacy`} element={<PrivacyPage />} />
          <Route path={`/term`} element={<TermPage />} />
          <Route path={`/*/`} element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

/*
function App() {
    // User Authentication
    const [user, loading, error] = useAuthState(auth);


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <AuthProvider>
          <Main />
          <SignUp />
        </AuthProvider>
      </header>
    </div>
  );
}
*/

export default App;
