import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, doc, setDoc, query, orderBy, limit, startAfter, getDocs, QueryDocumentSnapshot, getCountFromServer } from "firebase/firestore";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { bookConverter } from '../../firebase/converter';
import BookCard from '../../components/BookCard';

type BookListProps = {
  uid: string;
}

const BOOK_PAGE_SIZE = 5;

const BookList: React.FC<BookListProps> = ({ uid }) => {
  const [books, setBooks] = useState<Book[]>([]);

  useEffect(() => {
    (async () => {
      const booksRef = collection(db, "users", uid, "books")
      const q = query(booksRef, orderBy("createdAt", "desc"), limit(BOOK_PAGE_SIZE)).withConverter(bookConverter);
      const querySnapshot = await getDocs(q);
      const books = querySnapshot.docs.map((doc) => doc.data());
      setBooks(books);
    })();
  }, [uid]);

  return (
    <div>
      <h2>最近追加した書籍</h2>
      {(books.length === 0) && <p>まだ書籍がありません</p>}
      <ul style={{
        paddingInlineStart: "0px",
        width: "90%",
        margin: "16px",
      }}>
        {books.map((book: Book) => {
          return (
            <BookCard key={book.id} book={book} height={160} />
          );
        })}
      </ul>
    </div>
  );
}

export default BookList;