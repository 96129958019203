import React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const InstallApp = () => {
  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
        <h3>もっと便利なアプリをどうぞ！</h3>

        <div style={{
          marginTop: "16px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}>
          <img src="app-image.png" alt="アプリ" style={{
            width: "160px",
            marginLeft: "40px"
          }} />
          <div style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}>
            <div>
              <a href="https://apps.apple.com/jp/app/fusen-%E8%AA%AD%E6%9B%B8%E3%83%A1%E3%83%A2%E7%AE%A1%E7%90%86%E3%82%A2%E3%83%97%E3%83%AA/id1585547803?itsct=apps_box_badge&amp;itscg=30200"
                style={{
                  display: "inline-block",
                  overflow: "hidden",
                  borderRadius: "8px",
                  width: "120px",
                  height: "40px"
                }}>
                <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1633132800&h=70ad2160da64d6db7e4c235d39af22a1"
                  alt="Download on the App Store"
                  style={{
                    borderRadius: "8px",
                    width: "120px",
                    height: "40px"
                  }} />
              </a>
            </div>
            <ul>
              <li>書籍のバーコードを読み取り簡単に登録できます！</li>
              <li>書籍をコレクションで自由に分類できます！</li>
              <li>書籍を読んで残したいメモを複数保存可能です！</li>
              <li>書籍のテキストをカメラで読み込んで手軽に引用できます！</li>
              <li>読書中の書籍をウィジェットに表示して読み忘れを防止！</li>
              <li>ログインすれば複数のデバイスでデータを共有できます！</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstallApp;