// TODO: 「signInWithRedirect」を使用する場合は、ベスト プラクティスに従ってください。
// サードパーティのストレージ アクセスをブロックするブラウザで signInWithRedirect を使用する場合のベストプラクティス
import { auth } from './app'
import { signInWithRedirect, getRedirectResult, GoogleAuthProvider } from "firebase/auth";

export async function authWithGoogle() {
    const provider = new GoogleAuthProvider();

    // To apply the default browser preference instead of explicitly setting it.
    auth.useDeviceLanguage();
    signInWithRedirect(auth, provider);

    // After the page redirects back
    const userCred = await getRedirectResult(auth);

    return userCred;
}
