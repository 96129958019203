import { Timestamp } from "firebase/firestore";

export class Memo {
  constructor(
    readonly id: string,
    readonly bookId: string,
    readonly text: string,
    readonly quote: string,
    readonly page: number | null,
    readonly imageURLs: string[],
    readonly tags: string[],
    readonly createdAt: Timestamp,
    readonly updatedAt: Timestamp,
  ) { }

  toString(): string {
    return this.text;
  }

  createdAtString(): string {
    const date = this.createdAt.toDate();
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  }

  createdAtDetailString(): string {
    const date = this.createdAt.toDate();
    return `${this.createdAtString()} ${date.getHours()}:${date.getMinutes()}`;
  }

  updatedAtString(): string {
    const date = this.updatedAt.toDate();
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  }

  updatedAtDetailString(): string {
    const date = this.updatedAt.toDate();
    return `${this.updatedAtString()} ${date.getHours()}:${date.getMinutes()}`;
  }
}