import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, QueryOrderByConstraint, setDoc, query, orderBy, limit, startAfter, getDocs, QueryDocumentSnapshot, getCountFromServer } from "firebase/firestore";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { db } from '../../firebase/app';
import { Memo } from '../../types/Memo';
import { MemoOrder } from '../../types/MemoOrder';
import { memoConverter } from '../../firebase/converter';
import MemoCard from '../../components/MemoCard';

type MemoListProps = {
  uid: string;
  order: MemoOrder;
}

const MEMO_PAGE_SIZE = 40;

const MemoList: React.FC<MemoListProps> = ({ uid, order }) => {
  const [memos, setMemos] = useState<Memo[]>([]);
  const [memosCount, setMemosCount] = useState<number>(0);
  const [lastDocument, setLastDocument] = useState<QueryDocumentSnapshot<Memo> | null>(null);

  useEffect(() => {
    (async () => {
      // Get memos
      const memosRef = collection(db, "users", uid, "memos");
      let orderConstraint: QueryOrderByConstraint;
      switch (order) {
        case MemoOrder.CreatedAt:
          orderConstraint = orderBy("createdAt", "desc");
          break;
        case MemoOrder.UpdatedAt:
          orderConstraint = orderBy("updatedAt", "desc");
          break;
      }
      const q = query(memosRef, orderConstraint, limit(MEMO_PAGE_SIZE)).withConverter(memoConverter);
      const querySnapshot = await getDocs(q);
      const memos = querySnapshot.docs.map((doc) => doc.data());
      setMemos(memos);
      setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
    })();
  }, [uid, order]);

  useEffect(() => {
    (async () => {
      // Get count of memos
      const memosRef = collection(db, "users", uid, "memos");
      const memosCountFromServer = await getCountFromServer(query(memosRef));
      setMemosCount(memosCountFromServer.data().count);
    })();
  }, [uid]);

  const hasNext = memosCount > memos.length;
  const loadNext = async () => {
    const memosRef = collection(db, "users", uid, "memos")
    const q = query(memosRef, orderBy("updatedAt"), startAfter(lastDocument), limit(MEMO_PAGE_SIZE)).withConverter(memoConverter);
    const querySnapshot = await getDocs(q);
    const newMemos = querySnapshot.docs.map((doc) => doc.data());
    setMemos(memos.concat(newMemos));
    setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        margin: "auto 16px"
      }} >
        {memos.map((memo: Memo) => {
          return (
            <div key={memo.id} style={{
              width: "45%",
              margin: "0 32px 0 0"
            }}>
              <MemoCard uid={uid} memo={memo} detail={true} />
            </div>
          );
        })}
      </div>
      {hasNext && <button onClick={loadNext}>次のページを読み込む</button>}
    </div>
  );
}

export default MemoList;