import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { auth } from './app'

export async function authWithApple() {
  const provider = new OAuthProvider('apple.com');
  provider.setCustomParameters({
    // Localize the Apple authentication screen in Japanese.
    locale: 'ja_JP'
  });

  try {
    const result = await signInWithPopup(auth, provider);
    // The signed-in user info.
    const user = result.user;

    // Apple credential
    const credential = OAuthProvider.credentialFromResult(result);
    if (credential) {
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;

      // IdP data available using getAdditionalUserInfo(result)
      // ...
      console.log(`accessToken: ${accessToken}`);
      console.log(`idToken: ${idToken}`);
    }
  } catch (e: any) {
    const errorCode = e.code;
    console.log(`errorCode: ${errorCode}`);
    const errorMessage = e.message;
    console.log(`errorMessage: ${errorMessage}`);
    // The email of the user's account used.
    const email = e.customData.email;
    console.log(`email: ${email}`);

    // The credential that was used.
    const credential = OAuthProvider.credentialFromError(e);
    console.log(`credential: ${credential}`);
  }
}