import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { auth } from '../../firebase/app';
import { db } from '../../firebase/app';
import { Memo } from '../../types/Memo';
import { Book } from '../../types/Book';
import { memoConverter, bookConverter } from '../../firebase/converter';
import { doc, updateDoc, getDoc, serverTimestamp } from "firebase/firestore";

const MemoPage = () => {
  const navigate = useNavigate();
  // User Authentication
  const [user, loading, error] = useAuthState(auth);
  const { id } = useParams();
  const [memo, setMemo] = useState<Memo | null>(null);
  const [book, setBook] = useState<Book | null>(null);

  const goBackToBook = () => {
    if (memo) {
      navigate(`/books/${memo.bookId}`);
    }
  }

  useEffect(() => {
    (async () => {
      if (!user) { return; }
      if (!id) { return; }
      const memoRef = doc(db, "users", user.uid, "memos", id).withConverter(memoConverter);
      const snapshot = await getDoc(memoRef);
      const memo = snapshot.data();
      if (memo) {
        setMemo(memo);
      }
    })();
  }, [id, user]);

  useEffect(() => {
      (async () => {
        if (!user) { return; }
        if (!memo) { return; }
          // Get books
        const bookRef = doc(db, "users", user.uid, "books", memo.bookId).withConverter(bookConverter);
        const snapshot = await getDoc(bookRef);
        const book = snapshot.data();
        if (book) {
          setBook(book);
        }
      })();
  }, [user, memo]);

  return (
    <>
      <div>
        {user && memo && <MemoView uid={user.uid} memo={memo} />}
        {user && book && <BookView book={book} />}
        <Button style={{marginTop: "16px", marginBottom: "60px"}} variant="outlined" onClick={goBackToBook}>書籍ページへ</Button>
      </div>
    </>
  );
};

type MemoViewProps = {
  uid: string;
  memo: Memo;
};

const MemoView: React.FC<MemoViewProps> = ({ uid, memo }) => {
  const [text, setText] = useState(memo.text);
  const [quote, setQuote] = useState(memo.quote);
  const [page, setPage] = useState(memo.page);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    const newText = text.trim();
    const newQuote = quote.trim();
    if (newText.length > 0 || newQuote.length > 0) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
}, [text, quote]);


  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onTextChange = (e: any) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const onQuoteChange = (e: any) => {
    e.preventDefault();
    setQuote(e.target.value);
  };

  const onPageChange = (e: any) => {
    e.preventDefault();

    const page = parseInt(e.target.value);
    if (isNaN(page)) { 
      e.target.value = "";
      return; 
    }

    setPage(page);
    e.target.value = page;
  };

  const onSave = async (e: any) => {
    e.preventDefault();

    const memoRef = doc(db, "users", uid, "memos", memo.id)
    await updateDoc(memoRef, {
      bookId: memo.bookId,
      text: text,
      quote: quote,
      page: page,
      imageURLs: memo.imageURLs,
      tags: memo.tags,
      createdAt: memo.createdAt,
      updatedAt: serverTimestamp(),
    });

    setSnackbarOpen(true);
  };

  return (
    <div>
       <Box
        component="form"
        sx={{
          margin: "16px",
          '& .MuiTextField-root': { m: 2, width: '80%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "16px",
        }}>
          <Typography variant="subtitle2" color="text.secondary" component="div">
            作成日 : {memo.createdAtDetailString()}
          </Typography>
          <Typography style={{marginLeft: "16px"}} variant="subtitle2" color="text.secondary" component="div">
            更新日 : {memo.updatedAtDetailString()}
          </Typography>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
        }}>
        <TextField
          id="text"
          label="メモ"
          multiline
          rows={6}
          defaultValue={text}
          onChange={onTextChange}
        />
        <TextField
          id="quote"
          label="書籍から引用"
          variant="filled" 
          multiline
          rows={6}
          inputProps={{ style: { color: "#666" } }}
          defaultValue={quote}
          onChange={onQuoteChange}
        />
        <TextField
          style={{width: "100px"}}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          id="standard-error"
          label="ページ"
          defaultValue={page}
          variant="standard"
          onChange={onPageChange}
        />
        </div>
      </Box>
      <Button disabled={!isSaveEnabled} variant="contained" onClick={onSave}>
        保存
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          保存しました
        </Alert>
      </Snackbar>
    </div>
  );
}

type BookViewProps = {
  book: Book;
};

const BookView: React.FC<BookViewProps> = ({ book }) => {
  return (
    <div>
      <hr />
      <div style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "row",
            marginTop: "20px",
        }}>
            <img style={{
                width: "60px",
                height: "80px",
            }} src={book.imageURL} alt="画像" />
            <div style={{
                paddingLeft: "24px",
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexDirection: "column",
            }}>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    {book.title}
                </Typography>
            </div>
        </div>
    </div>
  );
}

export default MemoPage;
