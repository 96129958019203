import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { Book } from '../types/Book';

type BookCardProps = {
  book: Book;
  height: number;
}

const BookCard: React.FC<BookCardProps> = ({ book, height }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/books/${book.id}`);
  };

  return (
    <>
      <Card onClick={onClick}
        style={{
          cursor: "pointer",
        }}
        sx={{
          display: 'flex',
          marginBottom: "8px",
          height: height + "px",
        }}>
        <CardMedia
          component="img"
          sx={{ padding: "0 0 0 8px", width: 80, objectFit: "contain" }}
          image={book.imageURL}
          alt={book.title}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="subtitle1" color="text.primary">
              {book.title}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {book.author}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {book.description}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </>
  );
}

export default BookCard;