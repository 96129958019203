import { Link } from "react-router-dom";

import AboutApp from '../../components/AboutApp';
import InstallApp from '../../components/InstallApp';

const AboutAppPage = () => {
  return (
    <>
      <AboutApp />
      <div style={{ marginTop: "40px" }}>
        <InstallApp />
      </div>
      <Link to={`/`}>ホームへ</Link>
    </>
  );
};

export default AboutAppPage;