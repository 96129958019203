import { useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { auth } from '../../firebase/app';
import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { bookConverter } from '../../firebase/converter';
import { collection, doc, setDoc, query, where, getDocs, getDoc, CollectionReference } from "firebase/firestore";

import MemoList from './MemoList';
import Loading from '../../components/Loading';
import { MemoOrder } from '../../types/MemoOrder';

const MemosPage = () => {
  // User Authentication
  const [user, loading, error] = useAuthState(auth);

  const [order, setOrder] = useState(MemoOrder.CreatedAt);

  const handleChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value as MemoOrder);
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <>
      <div style={{
        margin: "0 60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}>
        <h2>メモ一覧</h2>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="memo-order-label">並び順</InputLabel>
            <Select
              labelId="memo-order-label"
              id="memo-order"
              value={order}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={MemoOrder.CreatedAt}>作成日時</MenuItem>
              <MenuItem value={MemoOrder.UpdatedAt}>更新日時</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {user && <MemoList uid={user.uid} order={order} />}

    </>
  );
};

export default MemosPage;
