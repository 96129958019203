import React from 'react';
import { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";

import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { db } from '../firebase/app'
import { Memo } from '../types/Memo';
import { Book } from '../types/Book';
import { bookConverter } from '../firebase/converter';

type MemoCardProps = {
  uid: string;
  memo: Memo;
  detail: boolean; // 書籍を取得するかどうかのフラグ。別コンポーネントにした方がいいかも
}

const MemoCard: React.FC<MemoCardProps> = ({ uid, memo, detail }) => {
  const [book, setBook] = useState<Book | null>(null);
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/memos/${memo.id}`);
  };

  useEffect(() => {
    if (detail) {
      (async () => {
        // Get books
        const bookRef = doc(db, "users", uid, "books", memo.bookId).withConverter(bookConverter);
        const snapshot = await getDoc(bookRef);
        const book = snapshot.data();
        if (book) {
          setBook(book);
        }
      })();
    }
  }, [uid, memo]);

  return (
    <div>
      <Card onClick={onClick}
        style={{
          cursor: "pointer",
        }}
        sx={{
          display: 'flex',
          marginBottom: "8px",
        }}>
        <Box style={{
          width: "100%",
        }} sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }} >
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }} >
                <Typography variant="caption" color="text.secondary" component="div">
                  <p>作成日: {memo.createdAtString()}</p>
                </Typography>
                <Typography style={{ marginLeft: "8px" }} variant="caption" color="text.secondary" component="div">
                  <p>更新日: {memo.updatedAtString()}</p>
                </Typography>
              </div>
              {memo.page !== null && memo.page > 0 &&
                <Typography variant="caption" color="text.secondary" component="div">
                  <p>{memo.page}ページ</p>
                </Typography>
              }
            </div>
            {memo.quote.length > 0 && <div>
              <div style={{
                display: "flex",
                width: "100%",
                backgroundColor: "#f8f8f8",
                flexDirection: "row",
                justifyContent: "start",
              }} >
                <div style={{
                  width: "4px",
                  backgroundColor: "#ddd",
                }} />
                <div style={{
                  paddingLeft: "8px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}>
                  <Typography component="div" variant="subtitle1" color="text.secondary">
                    {memo.quote}
                  </Typography>
                </div>
              </div>
            </div>}
            <div style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "start",
              marginTop: "8px",
            }} >
              <Typography variant="subtitle1" color="text.primary" component="div">
                {memo.text}
              </Typography>
            </div>
            {book &&
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "8px",
              }} >
                <CardMedia
                  component="img"
                  sx={{ height: 32, width: 28, paddingRight: "8px" }}
                  image={book.imageURL}
                  alt={book.title}
                />
                <Typography variant="caption" color="text.secondary" component="div">
                  {book.title}
                </Typography>
              </div>
            }
          </CardContent>
        </Box>
      </Card>
    </div >
  );
}

export default MemoCard;