import { Book } from '../types/Book';
import { Collection } from '../types/Collection';
import { Memo } from '../types/Memo';
import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export const bookConverter = {
  toFirestore(book: Book): DocumentData {
    return {
      title: book.title,
      author: book.author
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Book {
    const data = snapshot.data(options)!;
    return new Book(
      snapshot.id,
      data.title,
      data.author,
      data.imageURL,
      data.description,
      data.impression,
      data.createdAt, // Date
      data.updatedAt, // Date,
      data.isFavorite,
      data.valuation,
      data.collectionIdn
    );
  }
};

export const memoConverter = {
  toFirestore(memo: Memo): DocumentData {
    return {
      text: memo.text,
      quote: memo.quote
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Memo {
    const data = snapshot.data(options)!;
    return new Memo(
      snapshot.id,
      data.bookId,
      data.text,
      data.quote,
      data.page,
      data.imageURLs,
      data.tags,
      data.createdAt, // Date
      data.updatedAt, // Date,
    );
  }
};

export const collectionConverter = {
  toFirestore(collection: Collection): DocumentData {
    return {
      color: collection.color,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Collection {
    const data = snapshot.data(options)!;
    return new Collection(
      snapshot.id,
      data.color,
    );
  }
};