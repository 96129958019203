import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <>
      <h1>お探しのページは見つかりませんでした。</h1>
      <div>
        <Link to={`/`}>ホームへ</Link>
      </div>
    </>
  );
};

export default NotFoundPage;