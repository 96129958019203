import React from 'react';
import Typography from '@mui/material/Typography';

const Loading = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "80px",
        }}>
            <Typography variant="body1" color="text.secondary">
                Loading...
            </Typography>
        </div>
    )
};

export default Loading;