// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// TODO: ここを環境によって書き換える
// Development
// const firebaseConfig = {
//   apiKey: "AIzaSyCYtG-yeda2SeU63Cq8Y_KSejlKjCutMxg",
//   authDomain: "fusen-development.firebaseapp.com",
//   projectId: "fusen-development",
//   storageBucket: "fusen-development.appspot.com",
//   messagingSenderId: "890432524283",
//   appId: "1:890432524283:web:fa49b848596f459073c55e",
//   measurementId: "G-N2F07SPD6C"
// };
// Produciton
const firebaseConfig = {
  apiKey: "AIzaSyCPBPkRArMtoI3dOfOdlcstZhHgynJutL8",
  authDomain: "fusen-production.firebaseapp.com",
  projectId: "fusen-production",
  storageBucket: "fusen-production.appspot.com",
  messagingSenderId: "947504450260",
  appId: "1:947504450260:web:756e3008e1173492e04941",
  measurementId: "G-NLLZ94SJRL"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(firebase);
export const db = getFirestore(firebase);
export const functions = getFunctions(firebase, "asia-northeast1");
