import React from 'react';
import { useState, useEffect } from 'react';

import { collection, QueryOrderByConstraint, setDoc, query, orderBy, limit, startAfter, getDocs, QueryDocumentSnapshot, getCountFromServer } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { BookOrder } from '../../types/BookOrder';
import { bookConverter } from '../../firebase/converter';
import BookCard from '../../components/BookCard';
import { Publication } from '../../types/Publication';

type PublishListProps = {
  uid: string;
  publications: Publication[];
  onClick: (publication: Publication) => void;
}

const PublishList: React.FC<PublishListProps> = ({ uid, publications, onClick }) => {
  return (
    <>
      {publications.length > 0 && <h2>検索結果</h2>}
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        margin: "auto 0"
      }} >
        {publications.map((publication: Publication, index: number) => {
          return (
            <div key={publication.title + index} style={{
              width: "45%",
              margin: "0 32px 0 0"
            }}>
              <PublicationCard publication={publication} onClick={onClick} />
            </div>
          );
        })}
      </div>
    </>
  );
}


type PublicationCardProps = {
  publication: Publication;
  onClick: (publication: Publication) => void;
}

const PublicationCard: React.FC<PublicationCardProps> = ({ publication, onClick }) => {
  return (
    <>
      <Card onClick={() => { onClick(publication) }}
        style={{
          cursor: "pointer",
        }}
        sx={{
          display: 'flex',
          marginBottom: "8px",
          height: "160px",
        }}>
        <CardMedia
          component="img"
          sx={{ width: 80 }}
          image={publication.thumbnailURL + ""}
          alt={publication.title}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="subtitle1" color="text.primary">
              {publication.title}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {publication.authors}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </>
  );
}

export default PublishList;