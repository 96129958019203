import React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const AboutApp = () => {
  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
        <h2>本を読んで気になったことをたくさんメモに残そう！</h2>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }} >
        </div>
      </div>
    </>
  );
}

export default AboutApp;