import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import { auth } from '../../firebase/app';
import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { bookConverter } from '../../firebase/converter';
import { collection, doc, addDoc, query, where, getDocs, getDoc, serverTimestamp } from "firebase/firestore";
import Loading from '../../components/Loading';

const NewMemoPage = () => {
    const navigate = useNavigate();

    // User Authentication
    const [user, loading, error] = useAuthState(auth);
    const { bookId } = useParams();
    const [book, setBook] = useState<Book | null>(null);

    const [text, setText] = useState("");
    const [quote, setQuote] = useState("");
    const [page, setPage] = useState<number | null>(null);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  
    useEffect(() => {
      const newText = text.trim();
      const newQuote = quote.trim();
      if (newText.length > 0 || newQuote.length > 0) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    }, [text, quote]);
  
    const onTextChange = (e: any) => {
      e.preventDefault();
      setText(e.target.value);
    };
  
    const onQuoteChange = (e: any) => {
      e.preventDefault();
      setQuote(e.target.value);
    };
  
    const onPageChange = (e: any) => {
      e.preventDefault();
  
      const page = parseInt(e.target.value);
      if (isNaN(page)) { 
        e.target.value = "";
        return; 
      }
  
      setPage(page);
      e.target.value = page;
    };
  
    const onSave = async (e: any) => {
      e.preventDefault();

      if (!user) { return; }
  
      const memosRef = collection(db, "users", user.uid, "memos");
      const docRef = await addDoc(memosRef, {
          "bookId": bookId,
          "text": text,
          "quote": quote,
          "page": page !== null ? Number(page) : null,
          "tags": [],
          "imageURLs": [],
          "createdAt": serverTimestamp(),
          "updatedAt": serverTimestamp(),
      });
      navigate(-1);
    };

    useEffect(() => {
        (async () => {
            if (!user) { return; }
            if (!bookId) { return; }

            // Get book
            const bookRef = doc(db, "users", user.uid, "books", bookId).withConverter(bookConverter);
            const snapshot = await getDoc(bookRef);
            const book = snapshot.data();
            if (book) {
                setBook(book);
            }
        })();
    }, [user, bookId]);

    if (!book) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <div>
                <h3>新規メモ作成</h3>
                <Box
                    component="form"
                    sx={{
                    margin: "16px",
                    '& .MuiTextField-root': { m: 2, width: '80%' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div style={{
                    display: "flex",
                    flexDirection: "column",
                    }}>
                    <TextField
                    id="text"
                    label="メモ"
                    multiline
                    rows={6}
                    defaultValue={text}
                    onChange={onTextChange}
                    />
                    <TextField
                    id="quote"
                    label="書籍から引用"
                    variant="filled" 
                    multiline
                    rows={6}
                    inputProps={{ style: { color: "#666" } }}
                    defaultValue={quote}
                    onChange={onQuoteChange}
                    />
                    <TextField
                    style={{width: "100px"}}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    id="standard-error"
                    label="ページ"
                    defaultValue={page}
                    variant="standard"
                    onChange={onPageChange}
                    />
                    </div>
                </Box>
                <Button disabled={!isSaveEnabled} variant="contained" onClick={onSave}>
                    保存
                </Button>
            </div>

            <hr />
            {user && book && <BookView book={book} />}
        </>
    );
};


type BookViewProps = {
book: Book;
};

const BookView: React.FC<BookViewProps> = ({ book }) => {
return (
    <div>
    <hr />
    <div style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "row",
            marginTop: "20px",
        }}>
            <img style={{
                width: "60px",
                height: "80px",
            }} src={book.imageURL} alt="画像" />
            <div style={{
                paddingLeft: "24px",
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexDirection: "column",
            }}>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    {book.title}
                </Typography>
            </div>
        </div>
    </div>
);
}

export default NewMemoPage;
