export class Book {
    constructor(
        readonly id: string,
        readonly title: string,
        readonly author: string,
        readonly imageURL: string,
        readonly description: string,
        readonly impression: string,
        readonly createdAt: Date,
        readonly updatedAt: Date,
        readonly isFavorite: boolean,
        readonly valuation: number,
        readonly collectionId: string,
    ) {}

    toString(): string {
        return this.title + ', by ' + this.author;
    }
}