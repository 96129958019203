import { Link } from "react-router-dom";

const TermPage = () => {
  return (
    <>
      <div>
        <h2 id="-">利用規約</h2>
        <p>この利用規約（以下、「本規約」といいます。）は、当方が提供するfusen（以下、「当アプリ」といいます。）における利用条件について定めたものです。当アプリを利用する者（以下「利用者」といいます。）は、本規約に従って当アプリをご利用頂きます。当アプリをダウンロード、インストールまたは使用する場合、利用者は本規約に同意したものとします。
        </p>
        <h2 id="-1-">第1条 利用者の責任と権利</h2>
        <p>利用者は、自己の責任で当アプリを利用するものとし、本規約に基づく義務に違反する行為、当アプリを利用してなされた一切の行為、及びそれらの行為の結果生じた損害（当方が被る可能性がある損害を含みます）につき一切の責任を負うことに同意するものとします。
          利用者の使用権は非独占的なものであり、第三者への再使用許諾、譲渡又は契約上の地位の承継等を行うことはできません。
          当アプリを稼働させる機器（スマートフォン、タブレットを含む）及びネットワーク等の環境は、利用者の費用と責任で準備するものとします。</p>
        <h2 id="-2-">第2条 利用料</h2>
        <p>当アプリの利用料は基本的に無料とします。</p>
        <p>当方が当アプリの使用の対価を求める場合には、利用者に事前に通知し同意を得るものとします。</p>
        <h2 id="-3-">第3条 知的財産権</h2>
        <p>当アプリを通じて提供される全てのデータ、文章、音声、映像、イラスト、情報及び当アプリに関する、著作権、商標権、肖像権を含む一切の権利は、当方又は当該権利を有する第三者に帰属します。</p>
        <h2 id="-4-">第4条 禁止事項</h2>
        <p>利用者は、当アプリの利用にあたり、次に掲げる行為を行ってはなりません。また、利用者の違反行為により当方又は第三者に損害が生じた場合、利用者はその損害を賠償する責任を負うものとします。</p>
        <ul>
          <li>当方の承認した以外の方法により当アプリを利用すること</li>
          <li>当アプリを個人的な利用範囲を超えて、商業・営利目的において利用すること</li>
          <li>当アプリ（又は当アプリが接続するサーバ及びネットワーク）の運営を妨げ又は支障を及ぼす行為を行うこと</li>
          <li>第三者になりすまして当アプリを利用する行為を行うこと</li>
          <li>当アプリに使用されている画像、データ、情報等の全てについて、その有償無償や形態の如何を問わず、事前に当方から書面による承諾を得ることなく、複製、転載、再配布等する行為を行うこと</li>
          <li>当方又は第三者の著作権、特許権、実用新案権、意匠権、商標権、ノウハウ等その他の一切の知的財産権を侵害する行為を行うこと</li>
          <li>その他、当方が合理的な理由に基づき不適切と判断する行為を行うこと</li>
        </ul>
        <h2 id="-5-">第5条 免責</h2>
        <p>当アプリは現状有姿で提供されます。当方は、当アプリの完全性、有用性、動作保証、特定の目的への適合性、使用機器への適合性その他一切の事項について保証しません。また、通信障害、システム機器等の瑕疵、障害又は当アプリの利用により利用者又は第三者が被った損害について、当方は一切の責任を負いません。
          利用者の操作により、当アプリが、他のアプリを呼び出す場合又は他のアプリの機能を利用する場合、当該アプリの仕様、動作及び効果等について、当方は一切の責任を負いません。</p>
        <h2 id="-6-">第6条 当アプリの変更、中断又は終了</h2>
        <p>当方は、利用者への事前の通知なく、当アプリの内容、表示、操作方法、運営方法等を変更し、又は当アプリの提供を中断、終了することができます。この場合、当方はかかる変更・中断等に起因して生じる事象について一切責任を負いません。
          利用者が本規約に定める事項の一つにでも違反した場合、当方は、なんらの通知を行うことなく当該利用者との間において本規約を解約し、当該利用者について、当アプリの利用を終了させることができることとします。</p>
        <h2 id="-7-">第7条 本規約の変更</h2>
        <p>本規約は、利用者の事前の承諾を得ることなく、予告なく変更される場合があります。変更後に当アプリを使用された場合、利用者は当該変更に同意したものとみなします。
          本規約を変更した場合、当方は、当アプリ上への掲載等、当方が適切と判断する方法で、利用者に通知又は公表します。</p>
        <h2 id="-8-">第8条 個人情報の取り扱い</h2>
        <p>当方は、個人情報を「プライバシーポリシー」に基づき、適切に取り扱うものとします。当方が取得する個人情報は、当アプリの提供・運営・開発・改善および利用者のサポートに必要な範囲とし、利用者の同意なく必要以上の個人情報を取得しません。
        </p>
        <h2 id="-9-">第9条 準拠法及び裁判管轄</h2>
        <p>本規約に関する準拠法は日本法とします。本規約又は当アプリに関連して当方と利用者間で紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
        <p>2021年9月15日制定</p>
      </div>
      <div>
        <Link to={`/`}>ホームに戻る</Link>
      </div>
    </>
  );
};

export default TermPage;