import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, doc, setDoc, query, orderBy, limit, where, startAfter, getDocs, QueryDocumentSnapshot, getCountFromServer } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { bookConverter } from '../../firebase/converter';
import { auth } from '../../firebase/app';

const BOOK_PAGE_SIZE = 20;

const CollectionPage = () => {
    // User Authentication
    const [user, loading, error] = useAuthState(auth);
    const { id } = useParams();

    const [books, setBooks] = useState<Book[]>([]);
    const [booksCount, setBooksCount] = useState<number>(0);
    const [lastDocument, setLastDocument] = useState<QueryDocumentSnapshot<Book> | null>(null);
  
  useEffect(() => {
    (async () => {
        if (!user) { return; }
        if (!id) { return; }
        // Get books
        const booksRef = collection(db, "users", user.uid, "books")
        const q = query(booksRef, where("collectionId", "==", id), orderBy("createdAt", "desc"), limit(BOOK_PAGE_SIZE)).withConverter(bookConverter);
        const querySnapshot = await getDocs(q);
        const books = querySnapshot.docs.map((doc) => doc.data());
        console.log(`BookList: ${books.length} books found.`)
        setBooks(books);
        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);

        // Get count of books
        const booksCountFromServer = await getCountFromServer(query(booksRef, where("collectionId", "==", id)));
        setBooksCount(booksCountFromServer.data().count);
        })();
  }, [user, id]);

  const hasNext = booksCount > books.length;
  const loadNext = async () => {
    if (!user) { return; }
    if (!id) { return; }

    const booksRef = collection(db, "users", user.uid, "books")
    const q = query(booksRef, where("collectionId", "==", id), orderBy("createdAt", "desc"), startAfter(lastDocument), limit(BOOK_PAGE_SIZE)).withConverter(bookConverter);
    const querySnapshot = await getDocs(q);
    const newBooks = querySnapshot.docs.map((doc) => doc.data());
    setBooks(books.concat(newBooks));
    setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  return (
    <div>
      <h1>書籍一覧</h1>
      <p>書籍数: {booksCount}</p>
      <p>読み込んだ書籍数: {books.length}</p>
      <ul className="todo-list">
        {books.map((book: Book) => {
          return (
            <BookView key={book.id} book={book} />
          );
        })}
      </ul>
      {hasNext && <button onClick={loadNext}>次のページを読み込む</button>}
      <p></p>
    </div>
  );
}

type BookViewProps = {
  book: Book;
}

const BookView: React.FC<BookViewProps> = ({ book }) => {
  return (
    <div>
      <h2>書籍一覧</h2>
      <p>タイトル: {book.title}</p>
      <p>description: {book.description}</p>
      <p>imageURL: {book.imageURL}</p>
      <img src={book.imageURL} alt="画像" />
      <Link to={`/books/${book.id}`}>詳細ページへ</Link>
    </div>
  );
}

export default CollectionPage;