import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, QueryOrderByConstraint, setDoc, query, orderBy, limit, startAfter, getDocs, QueryDocumentSnapshot, getCountFromServer } from "firebase/firestore";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { BookOrder } from '../../types/BookOrder';
import { bookConverter } from '../../firebase/converter';
import BookCard from '../../components/BookCard';

type BookListProps = {
  uid: string;
  order: BookOrder;
}

const BOOK_PAGE_SIZE = 40;

const BookList: React.FC<BookListProps> = ({ uid, order }) => {
  const [books, setBooks] = useState<Book[]>([]);
  const [booksCount, setBooksCount] = useState<number>(0);
  const [lastDocument, setLastDocument] = useState<QueryDocumentSnapshot<Book> | null>(null);

  useEffect(() => {
    (async () => {
      // Get books
      const booksRef = collection(db, "users", uid, "books");
      let orderConstraint: QueryOrderByConstraint;
      switch (order) {
        case BookOrder.CreatedAt:
          orderConstraint = orderBy("createdAt", "desc");
          break;
        case BookOrder.Title:
          orderConstraint = orderBy("title");
          break;
        case BookOrder.Author:
          orderConstraint = orderBy("author");
          break;
      }
      const q = query(booksRef, orderConstraint, limit(BOOK_PAGE_SIZE)).withConverter(bookConverter);
      const querySnapshot = await getDocs(q);
      const books = querySnapshot.docs.map((doc) => doc.data());
      setBooks(books);
      setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
    })();
  }, [uid, order]);

  useEffect(() => {
    (async () => {
      // Get count of books
      const booksRef = collection(db, "users", uid, "books");
      const booksCountFromServer = await getCountFromServer(query(booksRef));
      setBooksCount(booksCountFromServer.data().count);
    })();
  }, [uid]);

  const hasNext = booksCount > books.length;
  const loadNext = async () => {
    const booksRef = collection(db, "users", uid, "books")
    const q = query(booksRef, orderBy("createdAt", "desc"), startAfter(lastDocument), limit(BOOK_PAGE_SIZE)).withConverter(bookConverter);
    const querySnapshot = await getDocs(q);
    const newBooks = querySnapshot.docs.map((doc) => doc.data());
    setBooks(books.concat(newBooks));
    setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        margin: "auto 16px"
      }} >
        {books.map((book: Book) => {
          return (
            <div key={book.id} style={{
              width: "45%",
              margin: "0 32px 0 0"
            }}>
              <BookCard book={book} height={160} />
            </div>
          );
        })}
      </div>
      {hasNext && <button onClick={loadNext}>次のページを読み込む</button>}
    </div >
  );
}

export default BookList;