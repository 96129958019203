import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, QueryOrderByConstraint, setDoc, query, where, getDocs, orderBy, CollectionReference } from "firebase/firestore";

import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { Memo } from '../../types/Memo';
import { memoConverter } from '../../firebase/converter';
import MemoCard from '../../components/MemoCard';
import { MemoOrder } from '../../types/MemoOrder';

type MemoListProps = {
  uid: string;
  bookId: string;
  order: MemoOrder;
}

const MemoList: React.FC<MemoListProps> = ({ uid, bookId, order }) => {
  const [memos, setMemos] = useState<Memo[]>([]);

  useEffect(() => {
    (async () => {
      // Get memos
      const memosRef = collection(db, "users", uid, "memos");

      let orderConstraint: QueryOrderByConstraint;
      switch (order) {
        case MemoOrder.CreatedAt:
          orderConstraint = orderBy("createdAt", "desc");
          break;
        case MemoOrder.UpdatedAt:
          orderConstraint = orderBy("updatedAt", "desc");
          break;
      }
      const q = query(memosRef, where("bookId", "==", bookId), orderConstraint).withConverter(memoConverter);
      // インデックスがない?
      // const q = query(memosRef, where("bookId", "==", bookId), orderBy("updatedAt")).withConverter(memoConverter);
      const querySnapshot = await getDocs(q);
      const memos = querySnapshot.docs.map((doc) => doc.data());
      setMemos(memos);
    })();
  }, [uid, bookId, order]);

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "auto 16px"
    }} >
        {memos.map((memo: Memo) => {
          return (
            <div key={memo.id} style={{
              width: "45%",
              margin: "0 32px 0 0"
            }}> 
            <MemoCard uid={uid} memo={memo} detail={false} />
            </div>
          );
        })}
    </div>
  );
}

export default MemoList;