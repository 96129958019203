import { Link } from "react-router-dom";

const PrivacyPage = () => {
  return (
    <>
      <div>
        <h2>プライバシーポリシー</h2>
        <h2 id="-">はじめに</h2>
        <p>このプライバシーポリシー（以下、「本プライバシーポリシー」といいます。）は、当方が提供するfusen（以下、「当アプリ」といいます。）における、個人情報の扱いについて定めたものです。</p>
        <h2 id="-">取得する個人情報と目的</h2>
        <h3 id="firebase-analytics">Firebase Analytics</h3>
        <p>アプリの品質向上などに活用するため、Firebase Analyticsを使用してアプリの利用状況に関わる情報を取得しています。</p>
        <p>プライバシーポリシー: <a
          href="https://firebase.google.com/policies/analytics?hl=ja">https://firebase.google.com/policies/analytics?hl=ja</a>
        </p>
        <h3 id="firebase-crashlytics">Firebase Crashlytics</h3>
        <p>アプリの不具合調査や品質改善のため、Firebase Crashlyticsを使用してアプリのクラッシュ状況に関わる情報を取得しています。</p>
        <p>プライバシーポリシー: <a
          href="https://firebase.google.com/support/privacy?hl=ja">https://firebase.google.com/support/privacy?hl=ja</a>
        </p>
        <h3 id="-api">楽天ブックス書籍検索API</h3>
        <p>楽天ブックス書籍検索APIを使用して書籍情報を取得するため、書籍のバーコードの情報を取得しています。</p>
        <p><a href="https://webservice.rakuten.co.jp/" target="_blank">Supported by Rakuten Developers</a>
        </p>
        <p>プライバシーポリシー: <a href="https://privacy.rakuten.co.jp/">https://privacy.rakuten.co.jp/</a></p>
        <h3 id="-">お問い合わせの際の個人情報</h3>
        <p>アプリに関するお問い合わせに対応するため、必要に合わせて以下の情報を取得する可能性があります。</p>
        <ul>
          <li>送信元のメールアドレス</li>
          <li>お問い合わせ内容</li>
          <li>OS情報</li>
          <li>デバイス情報</li>
        </ul>
        <h2 id="-">個人情報の管理</h2>
        <p>当方は、当アプリの利用者の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、安全対策を実施し個人情報の厳重な管理を行ないます。</p>
        <h2 id="-">個人情報の第三者への開示・提供の禁止</h2>
        <p>当方は、次のいずれかに該当する場合を除き、第三者に個人を特定できる情報を提供することはありません。</p>
        <ul>
          <li>アプリ利用者本人の同意がある場合</li>
          <li>法令に基づき開示することが必要である場合</li>
        </ul>
        <h2 id="-">プライバシポリシーの変更について</h2>
        <p>当方は、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直してその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。</p>
        <h2 id="-">お問い合わせ先</h2>
        <p>本プライバシーポリシーにおける情報の取り扱いに関するお問い合わせ、ご相談は下記のメールアドレス宛にお申し出ください。 </p>
        <p>メールアドレス: support@fusen.app</p>
        <p>2021年9月15日制定</p>
      </div>
      <div>
        <Link to={`/`}>ホームに戻る</Link>
      </div>
    </>
  );
};

export default PrivacyPage;