import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { httpsCallable } from "firebase/functions";

import { auth } from '../../firebase/app';
import PublishList from './PublishList';
import { db, functions } from '../../firebase/app';
import { Book } from '../../types/Book';
import { bookConverter } from '../../firebase/converter';
import { collection, addDoc, setDoc, query, where, getDocs, getDoc, serverTimestamp } from "firebase/firestore";
import Loading from '../../components/Loading';
import { useDebounce } from '../../utils/useDebounce';
import { Publication } from '../../types/Publication';
import { ConfirmationDialog } from './ConfirmationDialog';

const NewBookPage = () => {
  const navigate = useNavigate();

  // User Authentication
  const [user, loading, error] = useAuthState(auth);

  const [title, setTitle] = useState('');
  const [publications, setPublications] = useState([] as Publication[]);

  const debouncedTitle = useDebounce(title, 600);
  const onTitleChange = (event: { target: { value: any; }; }) => setTitle(event.target.value);

  const [selectedPublication, setSelectedPublication] = useState(null as Publication | null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [value, setValue] = useState('Dione');

  const addNewBook = (uid: string, publication: Publication) => {
    (async () => {
      const booksRef = collection(db, "users", uid, "books");
      const docRef = await addDoc(booksRef, {
        "title": publication.title,
        "author": publication.authors.join(","),
        "imageURL": publication.thumbnailURL,
        "description": "",
        "impression": "",
        "createdAt": serverTimestamp(),
        "updatedAt": serverTimestamp(),
        "isFavorite": false,
        "valuation": 0,
        "collectionId": ""
      });
      navigate("/books/" + docRef.id);
      })();
  };

  const handleClose = (result: boolean) => {
    setOpenConfirmDialog(false);

    if (result && selectedPublication && user) {
      addNewBook(user.uid, selectedPublication);
    } else {
      console.log(`キャンセル`);
    }
  };

  const onPublicationClick = (publication: Publication) => {
    setSelectedPublication(publication);
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    if (debouncedTitle.length === 0) { return; }
    (async () => {
      const searchBooks = httpsCallable(functions, 'searchBooks');
      const result = await searchBooks({ title: title });
      if (result.data) {
        const data: any = result.data;
        if (data.Items) {
          const items = data.Items;
          const searchedPublications = items
            .map((item: any) => {
              return new Publication(item.title, item.author, item.largeImageUrl);
            })
            .filter((publication: Publication | null) => {
              return publication !== null;
            });
            setPublications(searchedPublications);
        }
      }
    })();
  }, [debouncedTitle]);

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <>
     <div style={{
        margin: "0 60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
      }}>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '40ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="title"
            variant="outlined"
            fullWidth
            placeholder="書籍名で検索"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MenuBookRoundedIcon />
                </InputAdornment>
              ),
            }}
            onChange={onTitleChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter')
                event.preventDefault();
            }}
          />
        </Box>
      </div>
      
      <div>
        {user && <PublishList uid={user.uid} publications={publications} onClick={onPublicationClick} />}
      </div>
      {selectedPublication &&
        <ConfirmationDialog
          id="ringtone-menu"
          publication={selectedPublication}
          keepMounted
          open={openConfirmDialog}
          onClose={handleClose}
          value={value}
        />
      }
    </>
  );
};

export default NewBookPage;
