import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from 'firebase/auth';

import { auth } from '../../firebase/app';
import BookList from './BookList';
import MemoList from './MemoList';
import CollectionList from './CollectionList';
import Loading from '../../components/Loading';
import AboutApp from '../../components/AboutApp';
import InstallApp from '../../components/InstallApp';
import { authWithGoogle } from '../../firebase/auth';
import { authWithApple } from '../../firebase/auth-apple';

const HomePage = () => {
  // User Authentication
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <>
      <div style={{
        padding: "0 16px",
        display: "flex",
        flexDirection: "column",
      }}>
        {user && <AuthenticatedView user={user} />}
        {!user && <UnauthenticatedView />}
        <div style={{ marginTop: "60px" }}>
          <InstallApp />
        </div>
      </div>
    </>
  );
};

// 未ログイン状態View
type UnauthenticatedViewProps = {
}

const UnauthenticatedView: React.FC<UnauthenticatedViewProps> = () => {
  const logInWithGoogle = async (event: any) => {
    event.preventDefault();
    let result = await authWithGoogle();
    console.log(`result=${result}`);
  };

  const logInWithApple = async (event: any) => {
    event.preventDefault();
    let result = await authWithApple();
    console.log(`result=${result}`);
  };


  return (
    <>
      <AboutApp />
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "20px",
      }}>
        <h3>
          ログインはこちら
        </h3>
        <img src="./btn_google_signin_light_normal_web@2x.png"
          style={{
            width: "180px",
            height: "auto",
            cursor: "pointer",
            margin: "0 0 16px 0"
          }}
          onClick={logInWithGoogle} />
        <img src="./btn_apple.png"
          style={{
            width: "180px",
            height: "auto",
            cursor: "pointer"
          }}
          onClick={logInWithApple} />
      </div>
    </>
  );
}

// ログイン状態View
type AuthenticatedViewProps = {
  user: User;
}

const AuthenticatedView: React.FC<AuthenticatedViewProps> = ({ user }) => {
  return (
    <>
      <div>
        <div style={{
          display: "flex"
        }}>
          <div style={{ width: "45%" }}>
            {user && <BookList uid={user.uid} />}
          </div>
          <div style={{ width: "45%" }}>
            {user && <MemoList uid={user.uid} />}
          </div>
        </div>
        {/* {user && <CollectionList uid={user.uid} />} */}
      </div>
    </>
  );
}

export default HomePage;
