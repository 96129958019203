import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import { auth } from '../../firebase/app';
import PublishList from './PublishList';
import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { bookConverter } from '../../firebase/converter';
import { collection, doc, setDoc, query, where, getDocs, getDoc, CollectionReference } from "firebase/firestore";
import Loading from '../../components/Loading';
import { useDebounce } from '../../utils/useDebounce';
import { Publication } from '../../types/Publication';

export interface ConfirmationDialogRawProps {
  id: string;
  publication: Publication;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (result: boolean) => void;
}

export function ConfirmationDialog(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>書籍を追加</DialogTitle>
      <DialogContent>
        <DialogContentText>
          「{props.publication.title}」を追加しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          キャンセル
        </Button>
        <Button onClick={handleOk}>追加</Button>
      </DialogActions>
    </Dialog>
  );
}
