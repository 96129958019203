import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { auth } from '../../firebase/app';
import MemoList from './MemoList';
import { db } from '../../firebase/app';
import { Book } from '../../types/Book';
import { bookConverter } from '../../firebase/converter';
import { collection, doc, setDoc, query, where, getDocs, getDoc, CollectionReference } from "firebase/firestore";
import Loading from '../../components/Loading';
import { MemoOrder } from '../../types/MemoOrder';

const BookPage = () => {
    // User Authentication
    const [user, loading, error] = useAuthState(auth);
    const { id } = useParams();

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <div>
                {user && id && <BookView uid={user.uid} bookId={id} />}
                {/* {user && <BookList uid={user.uid} />} */}
            </div>
        </>
    );
};

type BookViewProps = {
    uid: string;
    bookId: string;
};

const BookView: React.FC<BookViewProps> = ({ uid, bookId }) => {
    const navigate = useNavigate();
    const [book, setBook] = useState<Book | null>(null);
    const [order, setOrder] = useState(MemoOrder.CreatedAt);

    const handleChange = (event: SelectChangeEvent) => {
      setOrder(event.target.value as MemoOrder);
    };
  
    const onGoToBooks = () => {
        navigate(`/books`);
    }

    const onNewMemoClick = () => {
        if (book) {
            navigate(`/memos/new/${book.id}`);
        }
    };

    useEffect(() => {
        (async () => {
            // Get books
            const bookRef = doc(db, "users", uid, "books", bookId).withConverter(bookConverter);
            const snapshot = await getDoc(bookRef);
            const book = snapshot.data();
            if (book) {
                setBook(book);
            }
        })();
    }, [uid, bookId]);

    if (book !== null) {
        return (
            <div>
                <div style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "row",
                    marginTop: "20px",
                }}>
                    <img style={{
                        width: "160px",
                        height: "220px",
                    }} src={book.imageURL} alt="画像" />
                    <div style={{
                        paddingLeft: "24px",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                        flexDirection: "column",
                    }}>
                        <Typography component="div" variant="subtitle1" color="text.primary">
                            タイトル: {book.title}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            著者:{book.author}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            説明:{book.description}
                        </Typography>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    margin: "0 60px",
                }}>
                    <h2>メモ一覧</h2>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                    }}>
                        <Button variant="contained" onClick={onNewMemoClick}>新規メモ作成</Button>
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel id="memo-order-label">並び順</InputLabel>
                            <Select
                            labelId="memo-order-label"
                            id="memo-order"
                            value={order}
                            label="Age"
                            onChange={handleChange}
                            >
                            <MenuItem value={MemoOrder.CreatedAt}>作成日時</MenuItem>
                            <MenuItem value={MemoOrder.UpdatedAt}>更新日時</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <MemoList uid={uid} bookId={book.id} order={order} />
                <Button style={{marginTop: "60px"}} variant="outlined" onClick={onGoToBooks}>書籍一覧へ戻る</Button>
            </div>
        );
    } else {
        return (
            <div>
                <p>エラー発生</p>
                <Button variant="outlined" onClick={onGoToBooks}>書籍一覧へ戻る</Button>
            </div>
        );
    }
}

export default BookPage;
