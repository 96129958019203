export class Publication {
  title: string
  authors: string[]
  thumbnailURL: string | null

  constructor(title: string, authors: string[], thumbnailURL: string | null) {
    this.title = title;
    this.authors = authors;
    this.thumbnailURL = thumbnailURL;
  }
}