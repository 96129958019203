import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, doc, setDoc, query, limit, getDocs, orderBy, CollectionReference } from "firebase/firestore";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { db } from '../../firebase/app';
import { Memo } from '../../types/Memo';
import { memoConverter } from '../../firebase/converter';
import MemoCard from '../../components/MemoCard';

type MemoListProps = {
  uid: string;
}

const MEMO_PAGE_SIZE = 5;

const MemoList: React.FC<MemoListProps> = ({ uid }) => {
  // TODO: 書籍情報を取得する
  const [memos, setMemos] = useState<Memo[]>([]);

  useEffect(() => {
    (async () => {
      // Get memos
      const memosRef = collection(db, "users", uid, "memos");
      const q = query(memosRef, orderBy("createdAt", "desc"), limit(MEMO_PAGE_SIZE)).withConverter(memoConverter);
      const querySnapshot = await getDocs(q);
      const memos = querySnapshot.docs.map((doc) => doc.data());
      setMemos(memos);
    })();
  }, [uid]);

  return (
    <div>
      <h2>最近追加したメモ</h2>
      {(memos.length === 0) && <p>まだメモがありません</p>}
      <ul style={{
        paddingInlineStart: "0px",
        width: "90%",
        margin: "16px",
      }}>
        {memos.map((memo: Memo) => {
          return (
            <MemoCard key={memo.id} uid={uid} memo={memo} detail={true} />
          );
        })}
      </ul>
    </div>
  );
}

export default MemoList;